<template>
  <div class="app-container">

    <a-card class="box-card">
      <a-form ref="config" :form="form" :layout="formLayout">
        <a-form-item
          label="保税仓库"
          :label-col="formItemLayout.labelCol"
          :wrapper-col="formItemLayout.wrapperCol">
          <a-select
            allow-clear
            placeholder="选择"
            v-decorator="['e_warehouse_id', {rules: [{required: true, message: '必填项，请填写信息'}]}]"
          >
            <a-select-option v-for="item in warehouseList" :key="item.code" :label="item.name" :value="item.id">
              {{ item.name }}
            </a-select-option>
          </a-select>
        </a-form-item>
        <a-form-item
          label="普通仓库"
          :label-col="formItemLayout.labelCol"
          :wrapper-col="formItemLayout.wrapperCol">
          <a-select
            allow-clear
            placeholder="选择"
            v-decorator="['normal_warehouse_id', {rules: [{required: true, message: '必填项，请填写信息'}]}]"
          >
            <a-select-option v-for="item in warehouseNormalList" :key="item.code" :label="item.name" :value="item.id">
              {{ item.name }}
            </a-select-option>
          </a-select>
        </a-form-item>
        <a-form-item
          :label-col="formItemLayout.labelCol"
          :wrapper-col="formItemLayout.wrapperCol"
          label="开启审核">
          <a-switch
            v-decorator="['examine', {initialValue: false, valuePropName: 'checked'}]"
            placeholder="请选择"
          />
        </a-form-item>
        <a-form-item
          :label-col="formItemLayout.labelCol"
          :wrapper-col="formItemLayout.wrapperCol"
          label="允许超售">
          <a-switch
            v-decorator="['oversold', {initialValue: false, valuePropName: 'checked'}]"
            placeholder="请选择"
          />
        </a-form-item>
      </a-form>
      <div class="op-container">
        <a-button type="primary" @click="handlePublish" style="margin-top: 20px">提交</a-button>
      </div>
    </a-card>
  </div>
</template>
<script>
import { warehouse_list } from '@/api/c_wms_warehouse'
import { createAppletConfig, editAppletConfig, getAppletConfig } from '@/api/applet_shopmall_manage'

const pick = require('lodash.pick')
export default {
  name: 'ConfigCreate',
  data () {
    return {
      formLayout: 'horizontal',
      form: this.$form.createForm(this),
      warehouseList: [],
      warehouseNormalList: [],
      config: {}
    }
  },
  computed: {
    formItemLayout () {
      const { formLayout } = this
      return formLayout === 'horizontal'
        ? {
          labelCol: { span: 4 },
          wrapperCol: { span: 14 }
        } : {}
    }
  },
  created () {
    this.warehouseListData()
  },
  methods: {
    warehouseListData () {
      warehouse_list({ warehouse_goods_type: 2 }).then((res) => {
        console.log(res)
        this.warehouseList = res.data.entries
      }).finally(() => {
        this.commodityNormal()
      })
    },
    commodityNormal () {
      warehouse_list({ warehouse_goods_type: 1 }).then((res) => {
        console.log(res)
        this.warehouseNormalList = res.data.entries
      }).finally(() => {
        this.getAppletConfig()
      })
    },
    getAppletConfig () {
      getAppletConfig().then(res => {
        console.log('配置：', res)
        this.config = res.data
        this.setFormData()
      })
    },
    handlePublish () {
      this.form.validateFields((errors, values) => {
        if (!errors) {
          // 新增
          console.log(values)
          if (this.config.id) {
            values.id = this.config.id
            editAppletConfig(values).then(res => {
              console.log(res)
            })
          } else {
            createAppletConfig(values).then(res => {
              console.log(res)
            })
          }
        } else {
          console.log(errors)
        }
      })
      console.log(this.config)
    },
    setFormData () {
      new Promise((resolve) => {
        setTimeout(resolve, 0)
      }).then(() => {
        // this.config.e_warehouse_id = '' + this.config.e_warehouse_id
        // this.config.normal_warehouse_id = '' + this.config.normal_warehouse_id
        const formData = pick(this.config, ['e_warehouse_id', 'normal_warehouse_id', 'examine', 'oversold'])
        console.log('formData', formData)
        this.form.setFieldsValue(formData)
      })
    }
  }
}
</script>
<style>
@import "../../../assets/font/font.css";

.a-card {
  margin-bottom: 10px;
}

.a-tag + .a-tag {
  margin-left: 10px;
}

.input-new-keyword {
  width: 90px;
  margin-left: 10px;
  vertical-align: bottom;
}

.avatar-uploader .a-upload {
  width: 145px;
  height: 145px;
  border: 1px dashed #d9d9d9;
  border-radius: 6px;
  cursor: pointer;
  position: relative;
  overflow: hidden;
}

.avatar-uploader .a-upload:hover {
  border-color: #20a0ff;
}

.avatar-uploader-icon {
  font-size: 28px;
  color: #8c939d;
  width: 120px;
  height: 120px;
  line-height: 120px;
  text-align: center;
}

.avatar {
  width: 145px;
  height: 145px;
  display: block;
}

.op-container {
  display: flex;
  justify-content: center;
  margin-top: 20px;
}

/*谷歌*/
input::-webkit-outer-spin-button,
input::-webkit-inner-spin-button {
  -webkit-appearance: none;
}

/*火狐*/
input[type="number"] {
  -moz-appearance: textfield;
}
</style>
